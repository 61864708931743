import {Component, input} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'app-card-warning',
  templateUrl: './card-warning.component.html',
  styleUrl: './card-warning.component.scss',
  imports: [
    MatIcon,
    MatCardModule,
  ],
})
export class CardWarningComponent {
  readonly text = input.required<string>();
}
