import {inject, Injectable} from '@angular/core';
import {StorageMap} from '@ngx-pwa/local-storage';
import axios from 'axios';
import {BehaviorSubject, Observable} from 'rxjs';
import {LoginResponse} from '../../model/login-response.model';
import {Login} from '../../model/login.model';

// todo
axios.defaults.baseURL = 'https://api.stammtisch.app/0.1';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private storage = inject(StorageMap);

  public currentUser: Observable<LoginResponse | undefined>;
  public currentUserSubject: BehaviorSubject<LoginResponse | undefined>;
  readonly storageKeyUser: string = 'currentUser';

  constructor() {
    this.currentUserSubject = new BehaviorSubject<LoginResponse | undefined>(undefined);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUser$(): Observable<LoginResponse | undefined> {
    return this.currentUser;
  }

  public get currentUserValue(): LoginResponse | undefined {
    return this.currentUserSubject.value;
  }

  loadFromStorage() {
    const o = this.storage.get(this.storageKeyUser);
    o.subscribe(
      // todo function type error
      // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
      (value: LoginResponse | undefined | unknown) => {
        if (value === undefined) {
          console.warn('Could not load session from storage.');
          return;
        }
        this.currentUserSubject.next(value as LoginResponse); // todo
        console.log('yay loaded session from storage');
        // void this.router.navigateByUrl(RoutingPath.stammtisch);
      });
    // fixme
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    return o.toPromise();
  }

  login(login: Login): Promise<LoginResponse> {
    const url = '/auth';
    const result = axios.post(
      url,
      login,
    ).then((response) => {
      const loginResponse = response.data as LoginResponse;
      if (loginResponse.user && loginResponse.key) {
        this.storage.set(this.storageKeyUser, loginResponse).subscribe();
        this.currentUserSubject.next(loginResponse);
      }
      return loginResponse;
    });
    return result;
  }

  setPassword(password: string, token: string): Promise<void> {
    const url = '/newpassword/set';
    const data = {password: password, token: token};
    const result = axios.post(
      url,
      data,
    ).then(() => {
      return; // todo
    });
    return result;
    // return this.httpService.handleHttpRequest('/newpassword/set', HttpMethod.POST, {password: password, token: token}, [404]);
  }

  resetPassword(email: { email: string }): Promise<void> {
    const url = '/newpassword/request';
    const result = axios.post(
      url,
      email,
    ).then(() => {
      return; // todo
    });
    return result;
    // return this.httpService.handleHttpRequest('/newpassword/request', HttpMethod.POST, email, [404]);
  }

  logout(): void {
    // remove user from local storage to log user out
    this.storage.delete(this.storageKeyUser);
    this.currentUserSubject.next(undefined);
  }
}
